.slide-item {
  width: 100%;
  height: 300px;
  margin: 0 8px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slide-bg-1 {
  background-image: url("../assets/images/packet_ins_bg.jpg");
}

.slide-bg-2 {
  background-image: url("../assets/images/UI-bg.jpg");
}

.slide-bg-3 {
  background-image: url("../assets/images/scalable-bg.jpg");
}

.slide-bg-4 {
  background-image: url("../assets/images/report-bg.jpg");
}

.slide-bg-5 {
  background-image: url("../assets/images/ai-bg.jpg");
}

.slide-bg-6 {
  background-image: url("../assets/images/wfh-bg.jpg");
}

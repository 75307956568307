@keyframes circle {
  0% {
    transform: rotate(0deg) translateX(50px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

.animate-circle {
  animation: circle 10s linear infinite;
  top: 30%;
  left: 20%;
  right: 10%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 10;
}

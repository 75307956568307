.fade-in {
  animation: fadeIn 1s ease-in forwards;
}

.fade-out {
  animation: fadeOut 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInRight 1.5s ease-in-out forwards;
}

.slide-out-right {
  animation: slideOutRight 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

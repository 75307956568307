@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
@import url("https://cdn.tailwindcss.com");

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  /* outline: 1px solid red; */
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

img,
button {
  padding: 0;
  margin: 0;
}
